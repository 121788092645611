import React, { useEffect, useState } from "react";

export default function Subscribe({ item }) {
  const [data, setData] = useState(null);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);
  const [hoverDevUpdates, setHoverDevUpdates] = useState(false);
  const [hoverSubsForm, setHoverSubsForm] = useState(false);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  return (
    <section
      className="p-tb"
      id="subscribe"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container">
        <div className="text-center">
          {title && (
            <h1 style={{ color: titleColor }} className="fw-bolder">
              {title}
            </h1>
          )}
          <p className="mb-2 fs-24" style={{ color: color }}>
            {text}
          </p>
          <a
            href={data?.devUpdatesLink}
            className="d-block d-md-inline-block btn btn-lg fw-bolder m-3"
            style={{
              cursor: "pointer",
              color: hoverDevUpdates
                ? data?.devUpdatesButtonHoverTextColor
                : data?.devUpdatesButtonTextColor,
              background: hoverDevUpdates
                ? data?.devUpdatesButtonHoverBackground
                : data?.devUpdatesButtonBackground,
              minWidth: 300,
            }}
            onMouseEnter={() => setHoverDevUpdates(true)}
            onMouseLeave={() => setHoverDevUpdates(false)}
            target="_blank"
            rel="noreferrer"
          >
            {data?.devUpdatesButtonText}
          </a>
          <a
            href={data?.subsFormLink}
            style={{
              background: hoverSubsForm
                ? data?.subsFormButtonHoverBackground
                : data?.subsFormButtonBackground,
              color: hoverSubsForm
                ? data?.subsFormButtonHoverTextColor
                : data?.subsFormButtonTextColor,
              minWidth: 300,
            }}
            onMouseEnter={() => setHoverSubsForm(true)}
            onMouseLeave={() => setHoverSubsForm(false)}
            target="_blank"
            className="d-block d-md-inline-block btn btn-lg fw-bolder m-3"
            rel="noreferrer"
          >
            {data?.subsFormButtonText}
          </a>
        </div>
      </div>
    </section>
  );
}
