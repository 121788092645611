import React, { useState, useEffect } from "react";
import Parse from "parse";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Benefits from "./components/Benefits";
import TokenSale from "./components/TokenSale";
import RoadMap from "./components/RoadMap";
import Faq from "./components/Faq";
import BlogSection from "./components/BlogSection";
import BrandCharacterSlider from "./components/BrandCharacterSlider";
import Footer from "./components/Footer";
import Team from "./components/Team";
import Message from "./components/Message";
import Social from "./components/Social";
import Subscribe from "./components/Subscribe";
import Loading from "./components/Loading";

export default function Home() {
  const [sections, setSections] = useState([]);
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const sectionComponents = {
    about: About,
    roadmap: RoadMap,
    faq: Faq,
    header: Header,
    blog: BlogSection,
    benefits: Benefits,
    sale: TokenSale,
    team: Team,
    message: Message,
    social: Social,
    subscribe: Subscribe,
    footer: Footer,
    navbar: Navbar,
    characterSlider: BrandCharacterSlider,
  };

  const Section = Parse.Object.extend("Section");
  const Image = Parse.Object.extend("Image");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const sections = await getActiveSections();
        const images = await getImages();
        const socialAccounts = await getSocialAccounts();
        setSections(sections);
        setImages(images);
        setSocialAccounts(socialAccounts);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);

  const getActiveSections = async () => {
    const query = new Parse.Query(Section);
    query.descending("priority");
    query.equalTo("enabled", true);
    return await query.find();
  };

  const getImages = async () => {
    const query = new Parse.Query(Image);
    return await query.find();
  };

  const getSocialAccounts = async () => {
    const query = new Parse.Query(Section);
    query.equalTo("section", "social");
    const socialAccounts = await query.first();
    return socialAccounts?.get("data")?.socialAccounts;
  };

  return loading ? (
    <Loading />
  ) : (
    sections?.map((item) => {
      const SectionComponents = sectionComponents[item.get("section")];

      if (item.get("section") === "navbar") {
        return (
          <SectionComponents
            key={item.id}
            item={item}
            sections={sections}
            socialAccounts={socialAccounts}
            images={images}
          />
        );
      } else if (item.get("section") === "footer") {
        return (
          <SectionComponents
            key={item.id}
            item={item}
            sections={sections}
            images={images}
          />
        );
      } else {
        return <SectionComponents key={item.id} item={item} images={images} />;
      }
    })
  );
}
