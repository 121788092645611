import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function BlogSection({ item }) {
  const [data, setData] = useState([]);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="blog-section py-5"
      id="blog"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container">
        <div className="sec-title text-center">
          <h1 style={{ color: titleColor }} className="fw-bolder">
            {title}
          </h1>
          <p className="my-4">{text}</p>
        </div>
        <div className="row blogmain mt-0">
          <Swiper
            className="blog-slider style-2 owl-carousel d-none d-lg-block mb-5"
            modules={[Navigation]}
            onInit={(swiper) => {
              //   swiper.params.navigation.prevEl = prevRef.current;
              //   swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={3}
          >
            {data?.blogs?.map((blogItem, index) => (
              <SwiperSlide key={index}>
                <div className="item h-100">
                  <div className="blog-list py-5 h-100 white-bg">
                    <div className="blog-date">
                      {blogItem?.blogDate?.split(" ").map((item, index) => {
                        if (index === 1) {
                          return (
                            <span key={index} className="bigger">
                              {" "}
                              {item}{" "}
                            </span>
                          );
                        } else {
                          return <span key={index}>{item}</span>;
                        }
                      })}
                    </div>
                    <div className="blog-list-desc pe-5">
                      <h4>
                        <a
                          href={blogItem?.blogLink}
                          style={{ textDecoration: "none", color: color }}
                        >
                          {blogItem?.blogTitle}
                        </a>
                      </h4>
                      <p>{blogItem?.blogText}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            className="blog-slider style-2 owl-carousel d-none d-md-block d-lg-none"
            modules={[Navigation]}
            onInit={(swiper) => {
              //   swiper.params.navigation.prevEl = prevRef.current;
              //   swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={2}
          >
            {data?.blogs?.map((blogItem, index) => (
              <SwiperSlide key={index}>
                <div className="item h-100">
                  <div className="blog-list py-5 h-100 white-bg">
                    <div className="blog-date">
                      {blogItem?.blogDate?.split(" ").map((item, index) => {
                        if (index === 1) {
                          return (
                            <span key={index} className="bigger">
                              {" "}
                              {item}{" "}
                            </span>
                          );
                        } else {
                          return <span key={index}>{item}</span>;
                        }
                      })}
                    </div>
                    <div className="blog-list-desc pe-5">
                      <h4>
                        <a
                          href={blogItem?.blogLink}
                          style={{ textDecoration: "none", color: color }}
                        >
                          {blogItem?.blogTitle}
                        </a>
                      </h4>
                      <p>{blogItem?.blogText}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            className="blog-slider style-2 owl-carousel d-block d-md-none"
            modules={[Navigation]}
            onInit={(swiper) => {
              //   swiper.params.navigation.prevEl = prevRef.current;
              //   swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={1}
          >
            {data?.blogs?.map((blogItem, index) => (
              <SwiperSlide key={index}>
                <div className="item h-100">
                  <div className="blog-list py-5 h-100 white-bg">
                    <div className="blog-date">
                      {blogItem?.blogDate?.split(" ").map((item, index) => {
                        if (index === 1) {
                          return (
                            <span key={index} className="bigger">
                              {" "}
                              {item}{" "}
                            </span>
                          );
                        } else {
                          return <span key={index}>{item}</span>;
                        }
                      })}
                    </div>
                    <div className="blog-list-desc pe-5">
                      <h4>
                        <a
                          href={blogItem?.blogLink}
                          style={{ textDecoration: "none", color: color }}
                        >
                          {blogItem?.blogTitle}
                        </a>
                      </h4>
                      <p>{blogItem?.blogText}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
