import React, { useState } from "react";

export default function FaqItem({
  item,
  questionBorderTop,
  questionHoverTextColor,
  color,
}) {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <ul>
      <li
        className="list-group-item border-0 text-start px-0 py-3 border border-bottom"
        id="explanationItemWrapper"
      >
        <div
          id="explanationItemQuestion"
          className="fs-5 d-flex justify-content-between dont-break-out fw-bolder"
          onClick={() => setOpen(!open)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            color: hover ? questionHoverTextColor : color,
            borderTop: questionBorderTop,
          }}
        >
          {item?.question}
          <span style={{ width: 50 }}>
            <svg
              width="25"
              height="25"
              className={open ? "float-end rotated w-6 h-6" : "float-end"}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </span>
        </div>

        {open && (
          <div className="my-4 fs-5">
            {item?.answer?.map((item, index) => {
              if (item?.type === "text") {
                return (
                  <p key={index} className="dont-break-out">
                    {item?.content}
                  </p>
                );
              } else {
                return (
                  <p key={index} className="dont-break-out">
                    <a
                      href={item?.content}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: questionHoverTextColor,
                        textDecoration: "none",
                      }}
                    >
                      {item?.content}
                    </a>
                  </p>
                );
              }
            })}
          </div>
        )}
      </li>
    </ul>
  );
}
