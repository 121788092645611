import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function BrandLogoSlider({ item, images }) {
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const bgColor = item?.get("backgroundColor");
    const bg = item?.get("bgImage");
    const data = item?.get("data");
    setData(data);
    setBackgroundImage(bg);
    setBackgroundColor(bgColor);
    //eslint-disable-next-line
  }, []);

  const getImageById = (id) => {
    const img = images.find((img) => img.id === id);
    const logo = img?.get("image").toJSON();
    return logo?.url;
  };

  return (
    <div
      id="brandCharacterSlider"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
      }}
      className="py-4"
    >
      <div className="container">
        <Swiper
          className="relative flex flex-col overflow-hidden lg:flex-row d-none d-lg-block"
          loop
          slidesPerView={3}
        >
          {data?.characters?.map((character, index) => (
            <SwiperSlide key={index}>
              <div className="text-center">
                <img
                  src={getImageById(character?.imageId) || data?.image}
                  alt={`character-${index}`}
                  style={{ maxHeight: "23vh" }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className="relative flex flex-col overflow-hidden lg:flex-row d-none d-sm-block d-lg-none"
          loop
          slidesPerView={2}
        >
          {data?.characters?.map((character, index) => (
            <SwiperSlide key={index}>
              <div className="text-center">
                <img
                  src={getImageById(character?.imageId) || data?.image}
                  alt={`character-${index}`}
                  style={{ maxHeight: "23vh" }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className="relative flex flex-col overflow-hidden lg:flex-row d-block d-sm-none"
          loop
          slidesPerView={1}
        >
          {data?.characters?.map((character, index) => (
            <SwiperSlide key={index}>
              <div className="text-center">
                <img
                  src={getImageById(character?.imageId) || data?.image}
                  alt={`character-${index}`}
                  style={{ maxHeight: "23vh" }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
