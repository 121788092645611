import React, { useState } from "react";

export default function BenefitsItem({
  benefit,
  index,
  cardBoxShadow,
  showMoreColor,
  benefitBorder,
  cardBackground,
  cardHoverBackground,
  showMoreText,
  showLessText,
}) {
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);

  // const getImageById = (id) => {
  //   const img = images.find((img) => img.id === id);
  //   const logo = img?.get("image").toJSON();
  //   return logo?.url;
  // };

  return (
    <div className="col-md-6 mb-5">
      <div
        className="item wow fadeInUp h-100"
        style={{
          border: benefitBorder,
          background: hover ? cardHoverBackground : cardBackground,
          boxShadow: hover && cardBoxShadow,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="row mt-3">
          <div className="col-4 col-md-3">
            <img
              src={`/images/${benefit?.imageName}.png`}
              alt={`benefit-${index}`}
              className="w-100"
            />
          </div>

          <div className="col-8 col-md-9">
            <h3 className="fw-bolder">{benefit?.title}</h3>
            {benefit?.paragraphs?.map((p, index) => (
              <p key={index}>
                {show ? p : p.slice(0, 200)}{" "}
                <span
                  className="bg-transparent fw-bolder"
                  style={{ cursor: "pointer", color: showMoreColor }}
                  onClick={() => setShow(!show)}
                >
                  {show ? showLessText : showMoreText}
                </span>
              </p>
            ))}
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
}
