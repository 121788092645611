import React, { useEffect, useState } from "react";
import FaqItem from "./FaqItem";

export default function Faq({ item }) {
  const [data, setData] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleColor, setTitleColor] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="faq-section p-tb"
      id="faq"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container">
        <div className="sec-title text-center">
          <h1 style={{ color: titleColor }} className="fw-bolder mb-5">
            {title}
          </h1>
          <p>{text}</p>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div
              className="accordion md-accordion"
              id="accordionEx"
              role="tablist"
              aria-multiselectable="true"
            >
              {data?.faq?.map((item, index) => (
                <FaqItem
                  key={index}
                  item={item}
                  questionBorderTop={data?.questionBorderTop}
                  questionHoverTextColor={data?.questionHoverFontColor}
                  color={color}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
