import React, { useEffect, useState } from "react";

export default function Footer({ item, sections, images }) {
  const [data, setData] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [text, setText] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bg = item?.get("bgImage");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setColor(textColor);
    setBackgroundImage(bg);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const img = images.find((img) => img.id === data?.imageId);
    const logo = img?.get("image").toJSON();
    setLogo(logo?.url);
  }, [images, data?.imageId]);

  return (
    <footer
      id="#footer"
      className="footer-1"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 footer-box-1">
            <div className="footer-logo text-center text-md-start">
              <a href="/" title="">
                <img
                  src={logo || data?.image}
                  alt="Cp Silver"
                  style={{ maxWidth: 225 }}
                />
              </a>
            </div>
            <p className="text-center text-md-start">{text}</p>
          </div>
          <div className="col-md-6 footer-box-2">
            <div className="sec-title">
              <h4 className="widget-title text-center text-md-start">
                {data?.pagesTitle}
              </h4>
            </div>
            <ul className="footer-menu onepage">
              {sections?.map((section) => {
                if (
                  section?.get("section") !== "header" &&
                  section?.get("section") !== "footer" &&
                  section?.get("section") !== "navbar" &&
                  section?.get("section") !== "characterSlider"
                ) {
                  return (
                    <li key={section.id}>
                      <a
                        href={`#${section?.get("section")}`}
                        className={
                          section?.get("section") === "faq"
                            ? "text-uppercase text-decoration-none"
                            : "text-capitalize text-decoration-none"
                        }
                        style={{ color: color }}
                      >
                        {section?.get("section")}
                      </a>
                    </li>
                  );
                } else {
                  return <span key={section.id}></span>;
                }
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="copyrights text-center text-md-start">
              {data?.copyright}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
