import React, { useState, useEffect } from "react";

export default function Message({ item }) {
  const [data, setData] = useState(null);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  return (
    <section
      className="p-tb text-center"
      id="message"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container-fluid">
        <h1 className="fw-bolder my-3" style={{ color: titleColor }}>
          {title}
        </h1>
        <p className="my-3">{text}</p>
        <h4 className="my-3">{data?.subTitle}</h4>
        <p className="my-3">{data?.subText}</p>
        <a
          href={`mailto:${data?.email}`}
          style={{ color: data?.emailColor }}
          className="my-3"
        >
          {data?.email}
        </a>
        <div className="my-5"></div>
      </div>
    </section>
  );
}
