import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
export default function RoadMap({ item }) {
  const [data, setData] = useState(null);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const bgColor = item?.get("backgroundColor");
    const textColor = item?.get("fontColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="roadmap-sec p-tb white-sec"
      id="roadmap"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
        borderTop: data?.borderTop,
      }}
    >
      <div className="container">
        <div className="sec-title text-center">
          <h1 style={{ color: titleColor }} className="fw-bolder">
            {title}
          </h1>
        </div>
        <div className="sub-txt text-center">
          <p>{text}</p>
        </div>
        <Swiper
          className="relative flex flex-col overflow-hidden lg:flex-row roadmap-slider owl-carousel d-none d-xl-block"
          modules={[Navigation]}
          onInit={(swiper) => {
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={5}
        >
          {data?.roadmap?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="item">
                <span style={{ color: data?.dateColor }}>{item?.date}</span>
                <p style={{ color: data?.goalColor }}>{item?.goal}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className="relative flex flex-col overflow-hidden lg:flex-row roadmap-slider owl-carousel d-none d-lg-block d-xl-none"
          modules={[Navigation]}
          onInit={(swiper) => {
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={4}
        >
          {data?.roadmap?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="item">
                <span style={{ color: data?.dateColor }}>{item?.date}</span>
                <p style={{ color: data?.goalColor }}>{item?.goal}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className="relative flex flex-col overflow-hidden lg:flex-row roadmap-slider owl-carousel d-none d-sm-block d-lg-none"
          modules={[Navigation]}
          onInit={(swiper) => {
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={3}
        >
          {data?.roadmap?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="item">
                <span style={{ color: data?.dateColor }}>{item?.date}</span>
                <p style={{ color: data?.goalColor }}>{item?.goal}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className="relative flex flex-col overflow-hidden lg:flex-row roadmap-slider owl-carousel d-block d-sm-none"
          modules={[Navigation]}
          onInit={(swiper) => {
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={1}
        >
          {data?.roadmap?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="item">
                <span style={{ color: data?.dateColor }}>{item?.date}</span>
                <p style={{ color: data?.goalColor }}>{item?.goal}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
