import React, { useEffect, useState } from "react";
import BenefitsItem from "./BenefitsItem";

export default function Benefits({ item, images }) {
  const [data, setData] = useState(null);
  const [benefits, setBenefits] = useState([]);
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setBenefits(data?.benefits);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="benefits p-tb"
      id="benefits"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container">
        <div className="sec-title text-center">
          <h1 className="fw-bolder mb-3" style={{ color: titleColor }}>
            {title}
          </h1>
        </div>
        <p className="text-center mb-5">{text}</p>
      </div>
      <div className="container">
        <div className="benefits-boxes row">
          {benefits?.map((benefit, index) => (
            <BenefitsItem
              key={index}
              cardBoxShadow={data?.cardBoxShadow}
              benefit={benefit}
              index={index}
              showMoreColor={data?.showMoreColor}
              benefitBorder={data?.benefitBorder}
              cardBackground={data?.cardBackground}
              cardHoverBackground={data?.cardHoverBackground}
              showMoreText={data?.showMoreText}
              showLessText={data?.showLessText}
              images={images}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
