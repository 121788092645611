import React, { useState , useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Parse from "parse";
import "./App.css";
import Home from "./pages/home/Home";
import MdPagesLayout from './layout/MdPagesLayout';

function App() {
  Parse.initialize(
    process.env.REACT_APP_PARSE_APPLICATION_ID,
    process.env.REACT_APP_PARSE_JAVASCRIPT_KEY
  );
  Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;

  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);

  const PageHTML = Parse.Object.extend("PageHTML");

  const getPages = async () => {
    const query = new Parse.Query(PageHTML);
    return await query.find();
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const pages = await getPages();
        setPages(pages);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);

  return (
      <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {pages?.map((item) => {
            const { html, page } = item?.toJSON();
            return (
              <Route
                path={page}
                key={item?.id}
                element={<MdPagesLayout html={html} loading={loading} />}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
      </>
  );
}

export default App;
