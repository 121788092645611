import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function About({ item, images }) {
  const [data, setData] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [hover, setHover] = useState(false);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const img = images.find((img) => img.id === data?.imageId);
    const logo = img?.get("image").toJSON();
    setLogo(logo?.url);
  }, [images, data?.imageId]);

  return (
    <div
      className="about-section style-2 p-tb"
      id="about"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-6 about-left text-center text-md-start mt-3 order-2 order-md-0">
            <div className="sec-title mb-3">
              <h1 className="fw-bolder" style={{ color: titleColor }}>
                {title}
              </h1>
              <h4>{data?.subtitle}</h4>
            </div>
            <p>{text}</p>
            {data?.buttonVisibility && (
              <Link
                className="btn py-3 px-4 fw-bolder mt-2"
                style={{
                  background: hover
                    ? data?.buttonHoverBackground
                    : data?.buttonBackground,
                  color: hover
                    ? data?.buttonHoverFontColor
                    : data?.buttonFontColor,
                  minWidth: 200
                }}
                to={data?.buttonLink}
                onMouseLeave={() => setHover(false)}
                onMouseEnter={() => setHover(true)}
              >
                {data?.buttonText}
              </Link>
            )}
          </div>
          <div className="col-md-6 align-items-center justify-content-center text-center order-1 order-md-0">
            <img src={logo || data?.image} className="w-50" alt="about" />
          </div>
        </div>
      </div>
    </div>
  );
}
