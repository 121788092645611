import React, { useEffect, useState } from "react";

export default function Team({ item, images }) {
  const [data, setData] = useState(null);
  const [groups] = useState([]);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);

  useEffect(() => {
    const data = item?.get("data");
    data?.team?.forEach((member) => {
      if (!groups.includes(member?.group)) groups.push(member?.group);
    });
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    //eslint-disable-next-line
  }, []);

  const getImageById = (id) => {
    const img = images.find((img) => img.id === id);
    const logo = img?.get("image").toJSON();
    return logo?.url;
  };

  return (
    <div
      className="team-section p-tb"
      id="team"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div className="container">
        <div className="team-member text-center">
          <h1 style={{ color: titleColor }} className="fw-bolder">
            {title}
          </h1>
          <p className="mt-4 mb-5">{text}</p>
          {groups?.map((group, index) => (
            <div key={index}>
              <div className="sec-title text-center">
                <hr />
                <h3 className="my-5">{group}</h3>
              </div>
              <div className="row justify-content-center">
                {data?.team?.map((item, index) => {
                  if (item?.group === group) {
                    if (item?.bio) {
                      return (
                        <div
                          className="col-12 col-lg-4 wow fadeInUp"
                          key={index}
                        >
                          <div className="team d-block d-lg-flex">
                            <img
                              src={getImageById(item?.imageId) || item?.image}
                              style={{
                                border: data?.imageBorder,
                              }}
                              alt="team"
                            />
                            <div className="text-lg-start mt-3 mt-lg-0 ms-lg-3">
                              <h4>{item?.name}</h4>
                              <span className="fs-5">{item?.title}</span>
                              {item?.bio?.map((item, index) => (
                                <div key={index}>{item}</div>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="col-12 col-lg-4 wow fadeInUp"
                          key={index}
                        >
                          <div className="team ">
                            <img
                              src={getImageById(item?.imageId) || item?.image}
                              style={{
                                border: data?.imageBorder,
                              }}
                              alt="team"
                            />
                            <div className="mt-3">
                              <h4>{item?.name}</h4>
                              <span>{item?.title}</span>
                              {item?.bio?.map((item, index) => (
                                <div key={index}>{item}</div>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    return <span key={index}></span>;
                  }
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
