
import React, { useEffect, useState } from "react";
import PieChart, {
  Legend,
  Series,
  Label,
  Font,
  Connector,
  Animation
} from 'devextreme-react/pie-chart';

export default function TokenSale({ item }) {
  const [data, setData] = useState(null);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [color, setColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const textColor = item?.get("fontColor");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    setColor(textColor);
    setDataSource(data?.pieChart);
    //eslint-disable-next-line
  }, []);


  function customizeText(arg) {
    return `${arg.argument} - ${arg.value}%`;
  }

  return (
    <>
      <div
        className="token-sale p-tb"
        id="sale"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: backgroundColor,
          color: color,
        }}
      >
        <div className="container">
        </div>
        <div className="container">
          <div className="sec-title text-center">
            <h1 style={{ color: titleColor }} className="fw-bolder">
              {title}
            </h1>
          </div>
          <div className="sub-txt text-center">
            <p>{text}</p>
          </div>
        </div>
        <div id='sale' className='px-1 py-5'>
          <PieChart id="pie"
            palette="Bright"
            dataSource={dataSource}
            tooltip={{
              enabled: true,
            }}
            theme="material.blue.light"
          >
            <Animation
              easing="linear"
              duration={500}
              maxPointCountSupported={100}
            />
            <Legend
              horizontalAlignment="left"
              font={{ size: "18px" }}
              verticalAlignment="center"
              visible={false}
            />
            <Series argumentField="tokenDistrubution" valueField="percentage"  >
              <Label
                visible={true}
                position="columns"
                customizeText={customizeText}>
                <Font size={16} />
                <Connector visible={true} width={1} />
              </Label>
            </Series>
          </PieChart >
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 col-xxl-12 ">
              <div className="table-container w-100 p-3 custom-scrollbar">
                <table
                  className="table table-bordered table-hover"
                  style={{
                    border: `2px solid ${data?.tableBorderColor}`,
                    boxShadow: data?.boxShadow,
                  }}
                >
                  <thead
                    style={{ border: `2px solid ${data?.tableBorderColor}` }}
                  >
                    <tr>
                      {data?.headerRow?.map((header, index) => (
                        <th
                          scope="col"
                          style={{
                            border: `2px solid ${data?.tableBorderColor}`,
                            color: data?.tableFontColor,
                            background: data?.tableHeaderBackground,
                          }}
                          key={index}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody
                    style={{ border: `2px solid ${data?.tableBorderColor}` }}
                  >
                    {data?.rows?.map((row, index) => (
                      <tr
                        key={index}
                        style={{
                          border: `2px solid ${data?.tableBorderColor}`,
                          background:
                            index % 2 === 0
                              ? data?.tableRowBackground
                              : data?.tableRowSecondaryBackground,
                        }}
                      >
                        {row.map((tableData, index) => (
                          <td
                            key={index}
                            className="fw-bold align-middle py-1 py-md-4"
                            style={{
                              border: `2px solid ${data?.tableBorderColor}`,
                              color: data?.tableFontColor,
                            }}
                          >
                            {tableData}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="empty-45"></div>
              <div className="sec-title text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}
