import React, { useState, useEffect } from "react";
import GoogleTranslate from "../../../components/GoogleTranslate";

export default function Header({ item }) {
  const [data, setData] = useState(null);
  const [timer, setTimer] = useState(null);
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const data = item?.get("data");
    const bg = item?.get("bgImage");
    const title = item?.get("title");
    const titleColor = item?.get("titleColor");
    const text = item?.get("text");
    const bgColor = item?.get("backgroundColor");
    setData(data);
    setBackgroundColor(bgColor);
    setText(text);
    setTitleColor(titleColor);
    setTitle(title);
    setBackgroundImage(bg);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    data?.timerDate && setInterval(calculateTimeLeft, 1000);
    //eslint-disable-next-line
  }, [data?.timerDate]);

  const calculateTimeLeft = () => {
    const now = new Date();
    const icoDate = new Date(data?.timerDate);
    const timeLeft = icoDate - now;

    setTimer({
      ...timer,
      days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
    });
  };

  return (
    <div
      className="midd-container"
      id="header"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
      }}
    >
      <GoogleTranslate/>
      <div className="hero-main layout-3 white-sec">
        <div id="gold-tech-bg">
          <canvas
            className="particles-js-canvas-el"
            style={{ width: "100%", height: "100%" }}
          ></canvas>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-sm-12 hero-caption wow fadeIn text-center"
              style={{
                visibility: "visible",
                animationName: "fadeIn",
                zIndex: 1,
              }}
            >
              <div className="sec-title text-center" style={{ zIndex: 12312 }}>
                <h1 style={{ zIndex: 12321321321, color: titleColor }}>
                  {title?.split(" ").map((word, index) => {
                    if (index % 2 === 1) {
                      return (
                        <span
                          key={index}
                          style={{ color: data?.titleSecondaryColor }}
                          className="fw-bolder"
                        >
                          {" "}
                          {word}{" "}
                        </span>
                      );
                    } else {
                      return (
                        <span key={index} className="fw-bolder">
                          {word}
                        </span>
                      );
                    }
                  })}
                </h1>
                <h3 className="text-white mt-4">{text}</h3>
              </div>
            </div>
            <div
              className="col-sm-12 wow fadeIn d-flex justify-content-center align-items-center"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationName: "fadeIn",
                animationDelay: "0.5s",
                zIndex: 1,
              }}
            >
              {data?.timerVisibility && (
                <div
                  className="pre-sale-timer-outer"
                  style={{
                    border: data?.timerBorder,
                    color: data?.timerTextColor,
                  }}
                >
                  <div
                    className="pre-sale-timer style-2 py-3 py-md-4 px-2 px-md-4"
                    style={{ background: data?.timerBackground }}
                  >
                    <h3 className="mb-4 fw-bolder fs-1">{data?.timerText}</h3>
                    <div
                      id="clock"
                      className="align-items-center justify-content-center"
                    >
                      <ul className="w-100">
                        <li className="w-25 px-2">
                          <span
                            style={{
                              border: data?.timerSectionsBorder,
                              boxShadow: data?.timerSectionsShadow,
                            }}
                          >
                            {timer?.days ? timer?.days : '00'}
                            <em style={{ color: data?.timerTextColor }}>
                              {data?.timerDaysLabel}
                            </em>
                          </span>
                        </li>
                        <li className="w-25 px-2">
                          <span
                            style={{
                              border: data?.timerSectionsBorder,
                              boxShadow: data?.timerSectionsShadow,
                            }}
                          >
                            {timer?.hours ? timer?.hours : '00'}
                            <em style={{ color: data?.timerTextColor }}>
                              {data?.timerHoursLabel}
                            </em>
                          </span>
                        </li>
                        <li className="w-25 px-2">
                          <span
                            style={{
                              border: data?.timerSectionsBorder,
                              boxShadow: data?.timerSectionsShadow,
                            }}
                          >
                            {timer?.minutes ? timer?.minutes : '00'}
                            <em style={{ color: data?.timerTextColor }}>
                              {data?.timerMinutesLabel}
                            </em>
                          </span>
                        </li>
                        <li className="w-25 px-2">
                          <span
                            style={{
                              border: data?.timerSectionsBorder,
                              boxShadow: data?.timerSectionsShadow,
                            }}
                          >
                            {timer?.seconds ? timer?.seconds : '00'}
                            <em style={{ color: data?.timerTextColor }}>
                              {data?.timerSecondsLabel}
                            </em>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="hero-right-btn mt-4 mb-2 px-2">
                      <a
                        className="btn fw-bolder"
                        href={data?.timerButtonLink}
                        style={{
                          backgroundColor: hover
                            ? data?.timerButtonHoverBackground
                            : data?.timerButtonBackground,
                          color: hover
                            ? data?.timerButtonHoverFontColor
                            : data?.timerButtonFontColor,
                        }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        {data?.timerButtonText}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
